:root {
  --school-card-score-none-text: var(--color-white);
  --school-card-score-none-background-color: var(--color-gray-500);
  --school-card-score-low-text: var(--color-indigo-600);
  --school-card-score-low-background-color: var(--color-indigo-200);
  --school-card-score-medium-text: var(--color-indigo-600);
  --school-card-score-medium-background-color: var(--color-indigo-300);
  --school-card-score-high-text: var(--color-white);
  --school-card-score-high-background-color: var(--color-indigo-500);
  --school-card-background-color: unset;
  --school-card-border-width: var(--space-px);
  --school-card-border-radius: var(--border-radius-2x);
  --school-card-width: unset;
  --school-card-border: 1px solid var(--color-gray-200);
}
