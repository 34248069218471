:root {
  --footer-margin: 0;
  --footer-background: var(--color-indigo-100);
  --footer-color: var(--color-black);
  --footer-heading-label-color: var(--color-black);
  --footer-social-icon-background: var(--color-indigo-500);
  --footer-social-icon-background-hover: var(--color-indigo-600);
  --footer-social-icon-background-active: var(--color-indigo-600);
  --footer-social-icon-color: var(--color-white);
  --footer-social-icon-size: 32px;
  --footer-social-links-justify-content: center;
  --footer-social-links-gap: var(--space-6x);
  --footer-logo-color: var(--color-black);
  --footer-anchor-text-color: var(--color-indigo-500);
  --footer-anchor-text-color-hover: var(--color-indigo-600);
  --footer-anchor-text-color-active: var(--color-indigo-400);
  --footer-link-section-border: 1px solid var(--color-gray-200);
  --sticky-footer-background: var(--color-indigo-500);
  --footer-link-section-chevron-icon-color: var(--color-indigo-400);
  --footer-logo-max-width: 162px;
}

.footerDark {
  --footer-background: var(--color-black);
  --footer-color: var(--color-white);
  --footer-heading-label-color: var(--color-white);
  --footer-anchor-text-color: var(--color-indigo-400);
  --footer-anchor-text-color-hover: var(--color-indigo-300);
  --footer-anchor-text-color-active: var(--color-indigo-500);
  --footer-social-icon-background-hover: var(--color-indigo-300);
  --footer-social-icon-color: var(--color-white);
  --footer-logo-color: var(--color-white);
  --footer-link-section-border: 1px solid var(--color-gray-500);
}
