:root {
  --deals-border: unset;
  --deals-heading-font-size: var(--font-size-lg);
  --deals-heading-label-color: var(--color-white);
  --summary-pets-border: unset;
  --pet-icon-color: unset;
  --accomodation-info-container-margin-bottom: 10px;
  --summary-mgmt-co-name-display: block;
  --summary-sqft-display: none;

  @media (--screen-lg) {
    --summary-sqft-display: inline;
  }
}
