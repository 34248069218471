:root {
  --drawer-menu-trigger-color: var(--color-white);
  --drawer-menu-trigger-font-size: var(--font-size-xl);
  --drawer-menu-trigger-padding: 0 var(--space-6x);
  --drawer-menu-link-weight: bold;
  --drawer-menu-link-color: var(--color-indigo-500);
  --drawer-menu-link-hover-color: var(--color-indigo-600);
  --drawer-menu-link-background-color: var(--color-white);
  --drawer-menu-link-background-hover-color: var(--color-indigo-100);
  --drawer-menu-divider-margin-top: var(--space-3x);
  --drawer-menu-divider-border: 1px solid var(--color-gray-200);
  --drawer-menu-non-link-color: 1px solid var(--color-gray-500);
  --drawer-menu-non-link-background-color: var(--color-white);
}
