:root {
  --gallery-modal-header-favorite-share-button-background-color: var(
    --color-indigo-100
  );
  --gallery-modal-header-favorite-share-button-hover-background-color: var(
    --color-indigo-200
  );
  --gallery-modal-header-favorite-share-button-color: var(--color-indigo-500);
  --gallery-modal-header-favorite-share-button-hover-color: var(
    --color-indigo-500
  );
}
