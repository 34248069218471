:root {
  --filter-card-flex-direction: row;
  --filter-card-title-order: initial;
  --filter-card-image-order: initial;
  --filter-card-title-width: 50%;
  --filter-card-image-width: 50%;
  --filter-card-title-padding-top: var(--space-8x);
  --filter-card-title-padding-bottom: var(--space-5x);
  --filter-card-image-height: 250px;
  --filter-card-image-padding-top: var(--space-7x);
  --filter-card-apartment-count-padding: var(--filter-card-title-padding-top)
    var(--space-6x) var(--filter-card-title-padding-bottom);

  --filter-card-toggle-button-border-width: 2px;

  --filter-card-toggle-button-color: var(--color-indigo-500);
  --filter-card-toggle-button-hover-color: var(--color-indigo-600);

  --filter-card-toggle-button-border-color: var(--color-indigo-500);
  --filter-card-toggle-button-hover-border-color: var(--color-indigo-600);
  --filter-card-toggle-button-border-color-selected: var(--color-indigo-500);
  --filter-card-toggle-button-selected-hover-border-color: var(
    --color-indigo-600
  );

  --filter-card-toggle-button-hover-background-color: var(--color-white);
  --filter-card-toggle-button-background-color-selected: var(
    --color-indigo-500
  );
  --filter-card-toggle-button-background-color-selected-hover: var(
    --color-indigo-600
  );
  --filter-card-cta-border-radius: var(--border-radius-full);
}
