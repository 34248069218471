:root {
  --saved-search-edit-button-background-color: var(--color-indigo-100);
  --saved-search-edit-button-background-color-hover: var(--color-indigo-200);
  --saved-search-edit-button-border-color: var(--color-indigo-300);
  --saved-search-edit-button-border-color-hover: var(--color-indigo-400);
  --saved-search-save-button-font-size: var(--font-size-body);
  --saved-search-button-background-color: var(--color-pink-500);
  --saved-search-button-background-color-hover: var(--color-pink-600);
  --saved-search-button-background-color-active: var(--color-pink-700);
}
