:root {
  --expanded-list-closed-before-content: unset;
  --expanded-list-closed-before-padding: unset;
  --expanded-list-open-list-flex-wrap: unset;
  --expanded-list-open-list-padding: unset;
  --expanded-list-flex-wrap: unset;
  --expanded-list-width: unset;
  --expanded-list-padding: 0;
  --expanded-list-margin: 0;
  --expanded-list-display: flex;
  --expanded-list-flex-direction: unset;
  --expanded-list-list-style: none;
  --expanded-list-overflow-x: unset;
  --expanded-list-overflow-y: unset;
  --expanded-list-item-padding: 0;
  --expanded-list-item-margin: 0;
  --expanded-list-item-display: flex;
  --expanded-list-item-flex-direction: unset;
  --expanded-list-item-line-height: unset;
  --expanded-list-item-font-size: var(--font-size-body);
  --expanded-list-button-width: unset;
  --expanded-list-button-white-space: unset;
  --expanded-list-button-border-radius: unset;
  --expanded-list-button-background-color: unset;
  --expanded-list-button-color: unset;
  --expanded-list-button-padding: unset;
  --expanded-list-button-font-size: unset;
  --expanded-list-row-gap: 0;
  --expanded-list-justify-content: unset;
  --expanded-list-item-width: unset;
}
