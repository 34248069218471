:root {
  --location-divider-padding-bottom: var(--space-8x);
  --location-divider-top-of-page-padding-top: var(--space-3x);
}

@media (--screen-md) {
  :root {
    --location-divider-padding-bottom: var(--space-4x);
    --location-divider-top-of-page-padding-top: var(--space-8x);
  }
}
