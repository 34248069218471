:root {
  --seo-links-section-padding: var(--space-10x) 0 var(--space-16x) 0;
  --seo-links-section-padding-md: var(--space-10x) 0 var(--space-16x) 0;
  --seo-links-section-padding-lg: var(--space-10x) 0 var(--space-16x) 0;
  --seo-links-section-last-child-padding-bottom: var(--space-6x);
  --seo-links-section-before-margin: var(--space-6x) 0;
  --seo-links-section-before-margin-lg: var(--space-11x) 0;
  --seo-links-section-before-content: '';
  --seo-links-section-before-display: block;
  --seo-links-section-before-border-top: 1px solid var(--color-gray-200);
  --seo-links-section-border-bottom: 1px solid var(--color-gray-200);
  --seo-links-section-margin: 0 var(--space-3x);
  --seo-links-section-margin-md: 0 var(--space-6x);
  --seo-links-section-margin-lg: 0 var(--space-6x);
}
