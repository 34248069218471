:root {
  --nearby-properties-background-color: var(--color-black);
  --nearby-properties-heading-color: var(--color-white);
  --nearby-properties-copy-color: var(--color-white);
  --nearby-properties-grid-padding: var(--space-7x) var(--space-7x)
    var(--space-3x);
  --nearby-properties-header-margin-top: 53px;
  --nearby-properties-grid-display: grid;
  --nearby-properties-grid-row-gap: var(--space-4x);
  --nearby-properties-copy-display: block;
  --nearby-properties-grid-margin-top: var(--space-7x);
  --nearby-properties-heading-font-size: var(--font-size-5xl);
  --nearby-properties-heading-margin: 0 0 var(--space-3x);
  --nearby-properties-header-margin: 53px var(--space-6x) 0 var(--space-6x);
  --nearby-properties-line-height: 1.1;
  --nearby-properties-letter-spacing: -0.02em;

  @media (--screen-lg) {
    --nearby-properties-heading-font-size: var(--font-size-6xl);
    --nearby-properties-header-margin: var(--space-16x) var(--space-6x) 0
      var(--space-6x);
  }

  @media (--screen-lg) {
    --nearby-properties-grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
