:root {
  --table-font-weight: var(--font-weight-semibold);
  --table-background-color-odd: var(--color-indigo-100);
  --table-border: none;
  --table-text-align: right;
  --table-tbody-td-padding: var(--space-5x) 0;
  --table-tbody-td-color: var(--color-black);
  --table-tbody-td-first-child-padding: var(--space-3x);
  --table-tbody-td-first-child-color: var(--color-indigo-500);
}
