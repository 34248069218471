:root {
  --gallery-reaction-active-color: var(--color-white);
  --gallery-reaction-hover-color: var(--color-white);
  --gallery-reaction-base-color: var(--color-white);
  --gallery-reaction-background-color: rgb(0 0 0 / 50%);
  --gallery-reaction-filled-background-color: var(--color-white);
  --gallery-pill-background-color: var(--color-indigo-500);
  --gallery-pill-border-color: var(--color-indigo-400);
  --gallery-pill-box-shadow: initial;
  --gallery-pill-color: var(--color-white);
  --gallery-reaction-arrow-button-color: var(--color-indigo-500);
  --gallery-pill-arrow-border-color: var(--color-indigo-400);
  --gallery-reaction-arrow-button-hover-color: var(--color-indigo-600);
  --gallery-carousel-slide-border-radius: var(--border-radius-2x);
  --gallery-zindex: initial;
}
