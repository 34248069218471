:root {
  --anchor-direction: initial;
  --anchor-display: inline;
  --anchor-font-size: inherit;
  --anchor-gap: 0;
  --anchor-padding: 0;
  --anchor-placement: inherit;
  --anchor-position: static;
  --anchor-text-color-active: var(--color-indigo-700);
  --anchor-text-color-hover: var(--color-indigo-600);
  --anchor-text-color-default: var(--color-indigo-500);
  --anchor-text-color-dark-active: var(--color-blue-500);
  --anchor-text-color-dark-hover: var(--color-blue-300);
  --anchor-text-color-dark-default: var(--color-indigo-400);
  --anchor-text-decoration-hover: underline;
  --anchor-text-decoration-default: none;
  --anchor-text-alignment: inherit;
  --anchor-weight: var(--font-weight-semibold);
  --anchor-z-index: auto;
  --anchor-text-color-disabled: var(--color-gray-300);
  --anchor-align-self: unset;
  --anchor-line-height: normal;
}
