:root {
  --list-property-home-button-width: auto;
  --list-property-home-button-border-radius: var(--border-radius-2x);
  --list-property-home-container-flex-direction: column;
  --list-property-page-background: var(--color-indigo-100);
  --list-property-page-background-value-prop: var(--color-indigo-100);
  --list-property-page-theme-background: transparent;
  --list-property-page-theme-color: var(--color-black);
  --list-property-logos-margin: 0;
  --list-property-logos-gap: 61px;
  --list-property-block-flex-direction: column;
  --list-property-simplify-flex-direction: row-reverse;
  --list-property-home-text-max-width: 329px;
  --list-property-value-props-padding: var(--space-8x) 0;

  @media (--screen-md) {
    --list-property-value-props-padding: var(--space-8x) 0 var(--space-14x);
    --list-property-home-container-flex-direction: row;
    --list-property-block-flex-direction: row;
  }
}
