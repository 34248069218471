:root {
  --select-width: 100%;
  --select-height: 40px;
  --select-height-focused: 40px;
  --select-padding: 0 var(--space-4x);
  --select-display: inline-flex;
  --select-align-items: center;
  --select-position: relative;
  --select-justify-content: space-between;
  --select-item-height: 40px;
  --select-border-radius: var(--border-radius-2x);
  --select-border-width: 1px;
  --select-border-color: var(--color-gray-200);
  --select-border-color-hover: var(--color-gray-300);
  --select-background-color: var(--color-white);
  --select-background-color-hover: var(--color-indigo-100);
  --select-icon-color: var(--color-indigo-500);
  --select-trigger-gap: 0;
  --select-trigger-color: var(--color-gray-500);
  --select-item-background-color: var(--color-white);
  --select-item-background-color-hover: var(--color-indigo-100);
  --select-item-background-color-active: var(--color-indigo-100);
  --select-item-background-color-active-hover: var(--color-indigo-100);
  --select-item-background-color-selected: var(--color-indigo-500);
  --select-item-background-color-selected-hover: var(--color-indigo-500);
  --select-item-color: var(--color-indigo-500);
  --select-item-color-selected: var(--color-white);
  --select-item-padding: 0;
  --select-results-padding: var(--space-1x);
  --select-font-color: var(--color-gray-500);
  --select-results-width: 100%;
  --select-min-width: initial;
  --select-z-index: var(--z-index-5x);
}
