:root {
  --home-page-background: var(--color-black);
  --home-page-color: var(--color-white);
  --home-page-container-max-width: 1325px;
  --home-page-font-size: var(--font-size-body);
  --home-page-heading-font-size-mobile: var(--font-size-5xl);
  --home-page-heading-font-size-desktop: var(--font-size-6xl);
  --home-page-heading-font-weight-desktop: 500;
  --home-page-heading-font-weight-mobile: 500;
  --home-page-overflow: hidden;
}
