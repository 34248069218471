:root {
  --toggle-button-color: var(--color-indigo-500);
  --toggle-button-hover-color: var(--color-indigo-500);
  --toggle-button-color-selected: var(--color-white);
  --toggle-button-color-selected-hover: var(--color-white);
  --toggle-button-border-color: var(--color-gray-200);
  --toggle-button-hover-border-color: var(--color-gray-200);
  --toggle-button-selected-hover-border-color: var(--color-gray-200);
  --toggle-button-border-color-selected: var(--color-white);
  --toggle-button-hover-background-color: var(--color-indigo-100);
  --toggle-button-background-color-selected-hover: var(--color-indigo-500);
  --toggle-button-background-color-selected: var(--color-indigo-500);
  --toggle-button-font-weight-selected: var(--font-weight-normal);
  --toggle-button-border-width: 1px;
  --toggle-button-modal-color: var(--color-gray-500);
  --toggle-button-modal-hover-color: var(--color-gray-500);
  --toggle-button-modal-border-color: var(--color-gray-200);
  --toggle-button-modal-hover-border-color: var(--color-gray-200);
  --toggle-button-font-weight: var(--font-weight-semibold);
  --toggle-button-min-height: var(--space-10x);
  --toggle-button-group-gap: var(--space-3x);
}
