:root {
  --horizontal-scroll-container-position: relative;
  --horizontal-scroll-container-margin: initial;

  --horizontal-scroll-list-gap: 0;
  --horizontal-scroll-list-display: flex;
  --horizontal-scroll-list-overflow-x: auto;
  --horizontal-scroll-list-align-items: center;
  --horizontal-scroll-list-position: relative;
  --horizontal-scroll-list-height: auto;
  --horizontal-scroll-list-scroll-behavior: smooth;

  --horizontal-scroll-gradient-background-color: var(--color-white);
}
