:root {
  --thank-you-redesign-header-background-color: var(--color-indigo-300);
  --header-modal-close-color: var(--color-blue-400);
  --header-modal-background-color: var(--color-indigo-100);
  --property-image-border-color: var(--color-indigo-500);
  --loading-bar-wrapper-background-color: var(--color-indigo-200);
  --loading-bar-background-color: var(--color-indigo-500);
  --loading-bar-height: var(--space-1x);
  --loading-bar-max-width: 100%;
}
