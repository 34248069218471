:root {
  --marker-element-background: var(--color-violet-600);
  --marker-element-background-hover: var(--color-violet-600);
  --marker-element-background-visited: var(--color-black);
  --marker-element-active-transform: translateY(-14px) scale(1.2);
  --marker-element-position: initial;
  --marker-element-cursor: pointer;
  --marker-element-font-family: initial;
  --marker-element-line-height: initial;
}
