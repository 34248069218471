:root {
  --detail-page-container-vertical-margin: var(--space-8x);
  --detail-page-container-margin: var(--detail-page-container-vertical-margin) 0;
  --detail-page-footer-padding-bottom: var(--space-18x);
  --listing-info-media-unavail-divider-display: none;
  --listing-info-media-unavail-padding: 0;
  --sticky-box-offset-top: -280px;
  --sticky-box-offset-top-with-pmc: -316px;
  --sticky-box-top: 80px;
  --sticky-box-default-top: 15px;

  /* Rent doesn't have deals section, so reuse styles */
  --sticky-box-offset-top-with-deals: var(--sticky-box-offset-top);
  --sticky-box-offset-top-with-pmc-deals: var(--sticky-box-offset-top-with-pmc);

  @media --screen-md {
    --detail-page-footer-padding-bottom: var(--space-20x);
    --listing-info-media-unavail-padding: var(--space-8x) 0;
  }

  @media --screen-xl {
    --detail-page-footer-padding-bottom: 0;
  }
}
