:root {
  --email-capture-text-align: left;
  --email-capture-modal-width: 654px;
  --email-capture-gap: var(--space-3x);
  --email-capture-form-layout-inner-gap: var(--space-3x);
  --email-capture-form-section-width: 50%;
  --email-capture-flex-direction: column;
  --email-capture-flex-direction-screen-md: column;
  --email-capture-form-layout-flex-direction: row;
  --email-capture-form-layout-flex-direction-screen-md: row;
  --email-capture-form-layout-inner-flex-direction: row;
  --email-capture-form-layout-gap: var(--space-3x);
  --email-capture-padding: var(--space-6x) var(--space-4x) var(--space-4x);
  --email-capture-border: 1px solid var(--color-gray-200);
  --email-capture-border-left: 7px solid var(--color-indigo-500);
  --email-capture-form-button-disabled-color: var(--color-indigo-600);
  --email-capture-form-button-disabled-background-color: var(
    --color-indigo-200
  );
}
