:root {
  --page-header-background: var(--color-black);
  --page-header-text: var(--color-white);
  --page-header-height: var(--space-20x);
  --page-header-back-width: 52px;
  --page-header-with-back-button-height: var(--space-11x);
  --page-header-logo-color: var(--color-white);
  --page-header-border-bottom: inherit;
  --page-header-position: relative;
  --page-header-back-color: var(--color-white);
  --page-header-back-background-color: var(--color-indigo-500);
  --page-header-back-background-color-active: var(--color-indigo-400);
  --page-header-links-background-color: inherit;
  --page-header-links-color: var(--color-white);
  --page-header-links-color-hover: var(--color-indigo-300);
  --page-header-links-color-active: var(--color-indigo-500);
  --page-header-links-font-weight: bold;
  --page-header-links-text-decoration: none;
  --page-header-links-text-decoration-hover: underline;
  --page-header-favorite-color: var(--color-white);
  --page-header-favorite-color-hover: var(--color-indigo-300);
  --page-header-favorite-text-decoration: underline;
  --page-header-links-padding-left: var(--space-1x);

  --page-header-favorite-color-filled: var(--color-indigo-400);

  @media (--screen-md) {
    --page-header-with-back-button-height: 76px;
    --page-header-back-width: 76px;
  }
}
