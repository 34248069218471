:root {
  --heading-with-heading-font-text-transform: none;
  --heading-with-heading-font-font-weight: 400;
  --heading-with-body-font-text-transform: none;
  --heading-with-body-font-font-weight: 600;
  --heading-text-transform: none;
  --heading-text-align: inherit;
  --heading-border-bottom: 0;
  --heading-font-size: var(--font-size-body);
  --heading-font-weight: 400;
  --heading-padding: 0;
  --heading-margin: 0;
  --heading-color: var(--color-black);
  --heading-letter-spacing: -0.02em;
  --heading-line-height: var(--leading-tight);
}
